<template>
  <div class="template-container mobile-wrap">
    <div class="wrap-box">
      <!-- banner -->
      <div class="banner-box">
        <img :src="newsData.bannerImage" alt="" />
      </div>
      <!-- 标题 -->
      <div class="news-introduction">
        <h2>新闻动态</h2>
        <div class="english">NEWS CENTER</div>
      </div>

      <!--新闻分类 -->
      <div class="btn hide-scrollbar">
        <el-button
          v-for="(item, index) in newsClassifyData"
          :key="index + `${item}`"
          type="primary"
          round
          :class="{ active: chooseIndex == index }"
          @click="classifyClick(item, index)"
        >
          {{ item.name }}
        </el-button>
      </div>

      <!-- 页面展示 -->
      <div class="news-show">
        <div
          class="newsItem"
          v-for="(item, index) in classifyPageList"
          :key="'class' + index"
          @click="jumpDetail(item, index)"
        >
          <div class="left">
            <ml-image
              size="small"
              class="preview-cover"
              :src="item.newsCover"
            ></ml-image>
          </div>
          <div class="right">
            <div class="time">{{ item.createTime }}</div>
            <div class="line"></div>
            <div class="title ml-line-2">
              {{ item.title }}
            </div>
            <p class="ml-line-2">
              {{ item.digest }}
            </p>
          </div>
        </div>
        <!-- 分页 -->
        <div class="pagination">
          <el-pagination
            background
            small
            layout="prev, pager, next, total"
            :total="newsClassifyPage.total"
            @size-change="handleSizeChange"
            @current-change="handleCurrentChange"
            @prev-click="prevClick"
            @next-click="nextClick"
            popper-class="mobile-popper"
          />
        </div>
      </div>
    </div>
  </div>
</template>

<script setup>
import { getCurrentInstance, onMounted } from "vue";
import datajs from "../data.js";

const { proxy } = getCurrentInstance();

// 辅助参数
const {
  newsData,
  newsClassifyData,
  classifyPageList,
  chooseIndex,
  id,
  newsClassify,
  getNewsList,
  newsClassifyPage,
  getNewsPageList,
  classifyClick,
  jumpDetail,
  handleSizeChange,
  handleCurrentChange,
  prevClick,
  nextClick
} = datajs.apply(proxy);

//调用获取分类方法
onMounted(async () => {
  await getNewsList();
  const [firstItem] = newsClassifyData.value;
  if (firstItem) {
    newsClassifyPage.newsCategoryId = firstItem.id;
    getNewsPageList();
  }
});
</script>

<style lang="scss" scoped>
.template-container {
  &.mobile-wrap {
    .is-wrapper-box {
    }

    .wrap-box {
      background-color: rgba(244, 248, 253, 1);
      .banner-box {
        width: 100%;
        object-fit: cover;
        img {
          width: 100%;
          height: 100%;
        }
      }

      .news-introduction {
        padding: 30px 20px 0px;
        text-align: center;
        h2 {
          font-size: 20px;
          margin: 0px 0 5px;
        }
        .english {
          font-size: 12px;
          color: #999999;
          margin-bottom: 15px;
        }
      }

      .btn {
        display: flex;
        flex-wrap: nowrap;
        overflow-x: auto;
        padding: 10px 20px;

        .el-button {
          width: 95px;
          height: 30px;
          font-size: 14px;
          background-color: #fff;
          color: #000000;
          border: none;
          margin-right: 15px;
          cursor: none;

          &.active {
            background-color: #005de8;
            color: #fff;
          }
        }
      }

      .news-show {
        padding: 0 20px;
        .newsItem {
          width: 100%;
          margin: 20px 0;
          display: grid;
          grid-template-columns: 110px 1fr;

          .left {
            height: 100%;
            border-radius: 6px;
            overflow: hidden;
          }
          .right {
            height: 100%;
            margin-left: 13px;
            font-size: 14px;

            .time {
              color: #999999;
              font-size: 12px;
            }
            .line {
              width: 14px;
              height: 1px;
              background-color: #0b5ee2;
              margin: 5px 0;
            }
            .title {
              font-size: 14px;
              font-weight: bold;
              margin-bottom: 8px;
            }

            p {
              line-height: 18px;
              font-size: 12px;
            }
          }
        }

        .pagination {
          width: 100%;
          padding: 20px 0;
          display: flex;
          justify-content: center;

          :deep(.el-pagination.is-background .el-pager li.is-active) {
            background-color: #005de8;
          }
          :deep(.el-pagination.is-background .el-pager li) {
            background-color: #fff;
            box-shadow: 0 0 0 1px #dcdfe6;
            border-radius: 5px;
          }
          :deep(.el-pagination.is-background .btn-prev) {
            background-color: #fff;
            box-shadow: 0 0 0 1px #dcdfe6;
            border-radius: 5px;
            cursor: none;
            .el-icon {
              color: #000000;
            }
          }
          :deep(.el-pagination.is-background .btn-next) {
            background-color: #fff;
            box-shadow: 0 0 0 1px #dcdfe6;
            border-radius: 5px;
            cursor: none;
            .el-icon {
              color: #000000;
            }
          }

          :deep(.el-select .el-input__wrapper) {
            border-radius: 5px;
            height: 24px;
            cursor: none;
            .el-input__inner {
              cursor: none;
            }
          }
        }
      }
    }
  }
}
</style>
<style lang="scss">
.mobile-popper {
  width: 100px;
  .el-scrollbar {
    .el-scrollbar__wrap {
      .el-select-dropdown__list {
        > li {
          font-size: 12px !important;
          cursor: none;
        }
      }
    }
  }
}
</style>
