import { ref, reactive, nextTick } from "vue";
import { useRouter } from "vue-router";

export default function () {
  const proxy = this;

  const router = useRouter();

  const dataStore = proxy.$usePiniaModule("dataStore");

  const newsData = {
    // banner
    bannerImage: require("@/assets/images/news/news-banner-1.png"),
  };

  // 新闻分类
  const newsClassifyData = ref([]);

  // 新闻列表
  const classifyPageList = ref([]);

  // 获取新闻分类 -----------------

  // 选中数据
  const chooseIndex = ref(0);
  // 保存分类id
  const id = ref();
  //分类接口数据
  const newsClassify = reactive({
    name: "",
    orderByType: "",
    sortField: "",
  });

  // 获取新闻分类
  async function getNewsList() {
    try {
      const res = await proxy.$storeDispatch(
        "fetchGetNewsCategoryList",
        newsClassify
      );
      const result = res.result;
      newsClassifyData.value = result;
      // console.log(newsClassifyData);
    } catch (error) {
      console.log(error);
    }
  }

  //分类分页数据
  const newsClassifyPage = reactive({
    newsCategoryId: "",
    orderByType: "",
    pageNum: 1,
    pageSize: 10,
    sortField: "",
    status: true,
    title: "",
  });

  // 获取新闻列表
  async function getNewsPageList() {
    try {
      const res = await proxy.$storeDispatch(
        "fetchGetNewsPageListReporting",
        newsClassifyPage
      );
      const result = res.result;
      // console.log(result);
      classifyPageList.value = result;
      newsClassifyPage.total = res.total;
    } catch (error) {
      console.log(error);
    }
  }

  // 分类点击事件
  function classifyClick(item, index) {
    const call = () => {
      chooseIndex.value = index;
      newsClassifyPage.newsCategoryId = item.id;
      getNewsPageList();
    };

    // 记录鼠标操作
    dataStore.setGlobalClickRecord(
      function(params){
        const { value } = params;
        document
          .getElementsByClassName("news-introduction")[0]
          .getElementsByTagName("button")
          [value].click();
      },
      {
        value: index,
      }
    );
    call();
  }

  const jumpDetail = (item, index) => {
    const call = () => {
      router.push({
        path: "/newsInformationDetail",
        query: {
          id: item.id,
        },
      });
    };

    // 记录鼠标操作
    dataStore.setGlobalClickRecord(
      function(params){
        const { value } = params;
        document
          .getElementsByClassName("news-show")[0]
          .getElementsByClassName("newsItem")
          [value].click();
      },
      {
        value: index,
      }
    );
    call();
  };

  // 页容量发生改变时触发
  const handleSizeChange = (size) => {
    const call = () => {
      console.log("页容量改变", size);
      newsClassifyPage.pageSize = size;
      getNewsPageList();
    };

    call();
  };
  // 页码发生改变时触发
  const handleCurrentChange = (count) => {
    const call = () => {
      console.log("页码改变", count);
      newsClassifyPage.pageNum = count;
      getNewsPageList();
    };
    call();

    const pagerEl = document.getElementsByClassName("el-pager")[0];
    const liList = [...pagerEl.children].valueOf();
    const liIndex = liList.findIndex(
      (d) => d.ariaLabel.indexOf(`第 ${count} 页`) != -1
    );
    // 记录鼠标操作
    dataStore.setGlobalClickRecord(
      function(params){
        const { value } = params;
        document
          .getElementsByClassName("el-pager")[0]
          .getElementsByTagName("li")
          [value].click();
      },
      {
        value: liIndex,
      }
    );
  };


  // 监听
  // 上一页
  const prevClick = (count) => {
    console.log("上一页", count);
    newsClassifyPage.pageNum = count;

    // 记录鼠标操作
    dataStore.setGlobalClickRecord(function(){
      document
        .getElementsByClassName("el-pagination")[0]
        .getElementsByClassName("btn-prev")[0]
        .click();
    });
  };
  // 下一页
  const nextClick = (count) => {
    console.log("下一页", count);
    newsClassifyPage.pageNum = count;

    // 记录鼠标操作
    dataStore.setGlobalClickRecord(function(){
      document
        .getElementsByClassName("el-pagination")[0]
        .getElementsByClassName("btn-next")[0]
        .click();
    });
  };

  return {
    newsData,
    newsClassifyData,
    classifyPageList,

    chooseIndex,
    id,
    newsClassify,
    getNewsList,
    newsClassifyPage,
    getNewsPageList,
    classifyClick,
    jumpDetail,
    handleSizeChange,
    handleCurrentChange,
    prevClick,
    nextClick,
  };
}
