<template>
  <web-app v-if="isNavigatorClient"></web-app>
  <web-pc v-else></web-pc>
</template>

<script setup>
import webPc from "./client/webPc.vue";
import webApp from "./client/webApp.vue";

import {
  computed,
  onUnmounted,
  getCurrentInstance,
  inject,
  onMounted,
} from "vue";

const { proxy } = getCurrentInstance();

const mainStore = proxy.$usePiniaModule("mainStore");

const isNavigatorClient = computed(() => proxy.$isNavigatorClient());

const setHeaderProps = inject("setHeaderProps");

// inject("setHeaderProps", {
//   backgroundColor: "#0077ff33",
//   navItemTheme: "light",
//   logoTheme: "light",
//   position: "fixed",
// });

onMounted(() => {
  setHeaderProps({
    backgroundColor: "#0077ff33",
    navItemTheme: "light",
    logoTheme: "light",
    position: "fixed",
  });

  setTimeout(() => {
    window.scrollTo({ top: 0 });
  });
});

onUnmounted(() => {
  // 重置
  setHeaderProps(null);
});
</script>
