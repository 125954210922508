<template>
  <div class="template-container pc-wrap">
    <div class="wrap-box">
      <!-- banner -->
      <div class="banner-box">
        <img :src="newsData.bannerImage" alt="" />
      </div>
      <!-- 新闻状态 -->
      <div class="news-introduction is-wrapper-box">
        <h2 class="fontBold">新闻动态</h2>
        <div class="english fontRegular">NEWS CENTER</div>
        <div class="btn fontRegular">
          <el-button
            v-for="(item, index) in newsClassifyData"
            :key="index + `${item}`"
            type="primary"
            round
            :class="{ active: chooseIndex == index }"
            @click="classifyClick(item, index)"
          >
            {{ item.name }}
          </el-button>
        </div>
      </div>

      <!-- 页面展示 -->
      <div class="news-show is-wrapper-box">
        <div
          class="newsItem"
          v-for="(item, index) in classifyPageList"
          :key="'class' + index"
          @click="jumpDetail(item, index)"
        >
          <div class="left">
            <ml-image
              size="large"
              class="preview-cover"
              :src="item.newsCover"
            ></ml-image>
          </div>
          <div class="right">
            <div class="time fontRegular">{{ item.createTime }}</div>
            <!-- <div class="line"></div> -->
            <div class="title ml-line-2 fontBold">
              {{ item.title }}
            </div>
            <p class="ml-line-4 fontRegular">
              {{ item.digest }}
            </p>
          </div>
        </div>
        <!-- 分页 -->
        <div class="pagination">
          <el-pagination
            background
            layout="prev, pager, next, total"
            :total="newsClassifyPage.total"
            @size-change="handleSizeChange"
            @current-change="handleCurrentChange"
            @prev-click="prevClick"
            @next-click="nextClick"
          />
        </div>
      </div>
    </div>
  </div>
</template>

<script setup>
import {
  computed,
  toRefs,
  getCurrentInstance,
  ref,
  reactive,
  watch,
  onMounted,
} from "vue";
import datajs from "../data.js";

const { proxy } = getCurrentInstance();

// 辅助参数
const {
  newsData,
  newsClassifyData,
  classifyPageList,
  chooseIndex,
  id,
  newsClassify,
  getNewsList,
  newsClassifyPage,
  getNewsPageList,
  classifyClick,
  jumpDetail,
  handleSizeChange,
  handleCurrentChange,
  prevClick,
  nextClick,
} = datajs.apply(proxy);

//调用获取分类方法
onMounted(async () => {
  await getNewsList();
  const [firstItem] = newsClassifyData.value;
  if (firstItem) {
    newsClassifyPage.newsCategoryId = firstItem.id;
    getNewsPageList();
  }
});
</script>

<style lang="scss" scoped>
.template-container {
  &.pc-wrap {
    .is-wrapper-box {
      // width: calc(100vw - 620px);
      width: 100%;
      max-width: 1320px;
      margin: auto;
    }

    .wrap-box {
      background-color: rgba(244, 248, 253, 1);
      .banner-box {
        max-height: 5000px;
        height: 100%;
        width: 100%;
        object-fit: cover;
        img {
          width: 100%;
          height: 100%;
        }
      }

      .news-introduction {
        text-align: center;
        h2 {
          font-size: 34px;
          margin: 65px 0 5px;
        }
        .english {
          font-size: 16px;
          color: #999999;
          margin-bottom: 40px;
        }
        .btn {
          display: grid;
          /* justify-content: flex-start; */
          /* flex-wrap: wrap; */
          grid-template-columns: repeat(7, 1fr);
          grid-row-gap: 20px;

          .el-button {
            width: 125px;
            height: 40px;
            font-size: 20px;
            background-color: #fff;
            color: #000000;
            border: none;
            margin: 0 15px;

            &.active {
              background-color: #005de8;
              color: #fff;
            }
          }
        }
      }

      .news-show {
        .newsItem {
          height: 220px;
          width: 100%;
          margin: 20px 0;
          display: flex;
          // justify-content: center;
          align-items: center;
          cursor: pointer;

          .left {
            width: 385px;
            height: 100%;
            // background-color: pink;

            img {
              height: 100%;
              width: 100%;
              object-fit: cover;
            }
          }
          .right {
            width: 905px;
            height: 100%;
            margin-left: 30px;
            font-size: 14px;

            .time {
              color: #999999;
            }
            // .line {
            //   width: 40px;
            //   height: 1px;
            //   background-color: #0b5ee2;
            //   margin: 13px 0 25px 0;
            // }
            .title {
              font-size: 20px;
              font-weight: bold;
              margin: 20px 0;
            }

            p {
              line-height: 24px;
              color: rgba(102, 102, 102, 1);
            }
          }
        }

        .pagination {
          width: 100%;
          padding: 80px 0;
          display: flex;
          justify-content: center;

          :deep(.el-pagination.is-background .el-pager li.is-active) {
            background-color: #005de8;
          }
          :deep(.el-pagination.is-background .el-pager li) {
            background-color: #fff;
            box-shadow: 0 0 0 1px #dcdfe6;
            border-radius: 5px;
          }
          :deep(.el-pagination.is-background .btn-prev) {
            background-color: #fff;
            box-shadow: 0 0 0 1px #dcdfe6;
            border-radius: 5px;

            .el-icon {
              color: #000000;
            }
          }
          :deep(.el-pagination.is-background .btn-next) {
            background-color: #fff;
            box-shadow: 0 0 0 1px #dcdfe6;
            border-radius: 5px;

            .el-icon {
              color: #000000;
            }
          }

          :deep(.el-select .el-input__wrapper) {
            height: 32px;
            border-radius: 5px;
          }
        }
      }
    }
  }
}
</style>
